#home-4 {
  //background: none;
  /* height: 69px; */
  background-color: #500000cc;
  height: 80px;

  @media(max-width: 767px) {
    height: 70px !important;
  }
}

#home-bg-video-2 {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  //background: url(../video/websitewarehouse6.jpg) no-repeat;
  background-size: cover;
}

#home-overlay-2 {
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.email__design-s {
  color: #500000;
  text-decoration: none;
  font-weight: 800;
}

.email__design-s:hover {
  color: #ed2a2a;
}

