/* ================================================
    Default Theme
    -----------------------------------------------
    Fonts: Raleway & Open Sans
    -----------------------------------------------
    Colors:
        Red:                #FF0000
        Dark Grey:          #454545
        Grey:               #A1A0A1
        Light Grey:         #DCDCDC
        Black:              #000000
================================================ */


/* ================================================
|   |   |   |   Html, Body
================================================ */
*{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

.owl-carousel {
  z-index: 0 !important;
}

#home {
  background: none;
  height: 100%;
}

html, body {
  height: 100%;
}

body {
  color: #454545;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: 'raleway', 'sans-serif';*/
}

p {
  /*font-family: 'open sans', 'sans-serif';*/
  // font-size: 13px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

section {
  background: white;
  overflow: hidden;
}

/* ================================================
|   |   |   |   Preloader
================================================ */

.preloader {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.status {
  background-image: url("./assets/wine-preloader-2.gif");
  background-repeat: no-repeat;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -35px;
  margin-left: -35px;
}
/* ================================================
|   |   |   |   Navigation logo
================================================ */
.img-rsz {
  max-width: 150px;
  height: auto;
  margin-top: -5px;
  padding-left: 10px;
}

/* ================================================
|   |   |   |   Home
================================================ */
.home-bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  /*background: url("../video/ktmanagers_homepage-2.jpg") no-repeat;*/
  background-size: cover;
}

.home-overlay {
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.home-content {
  width: 100%;
  /*height: 100%;*/
  height: 100vh;
  display: table;
}

.home-content-inner {
  display: table-cell;
  vertical-align: middle;
}

.home-heading h1 {
  color: white;
  font-size: 65px;
  font-weight: 100;
  /*text-transform: uppercase;*/
  margin: 0;
  display: inline-block;
}

.home-heading h1 span {
  color: #cd0000;
  font-weight: 500;
  font-size: 1.12em;
}

.home-text p {
  color: white;
  font-size: 23px;
  font-weight: 600;
  margin: 8px 0 30px 0;
}

/* ================================================
|   |   |   |   Buttons
================================================ */

.btn-general {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 6px;
  padding: 12px 46px 12px 46px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.pay-btn-navbar {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0 6px;
  -webkit-transition: all .5s;
  transition: all .5s;
  padding: 8px 34px 8px 34px;
}

.btn-client-book {
  font-family: 'raleway', sans-serif !important;
  border-radius: 28px !important;
  font-size: 13px !important;
  text-transform: uppercase !important;
  margin: 0 6px !important;
  padding: 12px 30px 12px 30px !important;
  -webkit-transition: all .5s !important;
  transition: all .5s !important;
}

.btn-social-i {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 15px;
  //padding: 6px 12px 6px 12px;
  padding: 14px 14px 14px 14px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.btn-social-i-f {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 15px;
  /*padding: 6px 16px 6px 16px;*/
  //padding: 6px 12px 6px 12px;
  padding: 14px 14px 14px 14px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.btn-social-i-t {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 15px;
  //padding: 6px 11px 6px 11px;
  padding: 14px 14px 14px 14px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.btn-social-i-y {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 15px;
  //padding: 6px 11px 6px 11px;
  padding: 14px 14px 14px 14px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.btn-social-imdb {
  font-family: 'raleway', sans-serif;
  border-radius: 28px;
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 15px;
  //padding: 6px 11px 6px 11px;
  padding: 10px 10px 10px 10px;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.social-hr-s {
  border: 1px solid #a1a0a1;
}

.btn-home {
  color: #dcdcdc;
  border: 1px solid #dcdcdc;
}

.btn-home:hover,
.btn-home:focus {
  color: #dcdcdc;
  background-color: #cd0000;
  border: 1px solid #cd0000;
}

.btn-red {
  color: #dcdcdc;
  border: 1px solid #cd0000;
  background-color: #cd0000;

}

.btn-red:hover,
.btn-red:focus {
  color: #dcdcdc;
  background-color: #a1a0a1;
  border: 1px solid #454545;
}


.pay-quote-button-styles {
  font-weight: 800;
  font-size: 15px;
  color: #fff;
}

/*.pay-quote-button-styles:hover,*/
/*.pay-quote-button-styles:focus {*/
/*  color: #500000;*/
/*}*/

.btn-pay {
  color: #dcdcdc;
  border: 1px solid #f4f4f4;
  background:

  radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 0%),
  radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 0%),

  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),

  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),

  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),

  linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);;
}

.btn-moreInfo {
  color: #dcdcdc;
  border: 1px solid #f4f4f4;
  background: #500000;

  background: #f4f4f4;
  color: #500000;
  border: 2px solid #500000;

  .pay-quote-button-styles {
    color: #500000;
  }
}

.btn-moreInfo:hover,
.btn-moreInfo:focus {
  color: #500000;
  border: 2px solid #500000;
  background: #500000;

  .pay-quote-button-styles {
    color: #f4f4f4;
  }
}


.btn-pay:hover,
.btn-pay:focus {

  background-color: #500000 ;
  background: #500000;
  transition: all .5s;
  border: 1px solid #500000;
  color: #500000;
  border: 1px solid #fff;
}

.btn-instagram {
  color: white;
  background:

          radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
          radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),

          radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
          radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),

          radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
          radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),

          radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),

          linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  /*border: 1px solid rgb(82, 88, 207);*/
  border: 1px solid #f4f4f4;

}


.btn-instagram:hover,
.btn-instagram:focus {
  color: #6559ca;
  background: #f4f4f4;
  border: 1px solid rgb(160,160,255);
}

.btn-facebook {
  color: white;
  background: #3b5998;
}

.btn-facebook:hover,
.btn-facebook:focus {
  color: #3b5998;
  background: white;
  border: 1px solid #3b5998;
}

.btn-tiktok {
  color: white;
  /*background: #f4f4f4;*/
  background: #5bdcd2;
}

.btn-tiktok:hover,
.btn-tiktok:focus {
  color: #5bdcd2;
  background: white;
  border: 1px solid #5bdcd2;
}

.btn-youtube {
  color: white;
  background: #FF0000;
}

.btn-youtube:hover,
.btn-youtube:focus {
  color: #FF0000;
  background: white;
  border: 1px solid #FF0000;
}

.btn-twitter {
  color: white;
  background: #00aced;
}

.btn-twitter:hover,
.btn-twitter:focus {
  color: #00aced;
  background: white;
  border: 1px solid #00aced;
}

.btn-imdb {
  color: black;
  background: #F5C518;
}

.btn-imdb:hover,
.btn-imdb:focus {
  color: #F5C518;
  background: black;
  border: 1px solid #F5C518;
}

.btn-grey {
  color: #dcdcdc;
  background-color: #454545;
  border: 1px solid #454545;
}

.btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 3px 15px;
  border-radius: 4px;
  font-size: 22px;
  display: none;
  z-index: 50;
}

/* ================================================
|   |   |   |   Arrow Down
================================================ */

.arrow-down {
  position: absolute;
  left: 50%;
  bottom: 20px;
  color: #dcdcdc;
  font-size: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
  margin-left: -16px;
  z-index: 1;
}

.arrow-down:hover,
.arrow-down:focus {
  color: #cd0000;
}

/* ================================================
|   |   |   |   Content Boxes
================================================ */

.content-box-lg {
  padding: 120px 0;
}

.content-box-main {
  padding: 0 0 50px 0;
}

.content-box-main-2 {
  padding: 30px 0;
}

.content-box-team {
  padding: 40px 0 10px 0;
  background-color: #dcdcdc;
}

.content-box-statement {
  padding: 80px 0;
}

.content-box-md {
  padding: 100px 0;
}

.content-box-md-stats {
  padding: 40px 0 60px 0;
}

.content-box-md-brands {
  padding: 40px 0 40px 0;
  border: 1px solid #a1a0a1;
}

.content-box-sm {
  padding: 90px 0;
}

.content-box-services {
  padding: 40px 0 130px 0;
  background-color: #dcdcdc;
  border-top: 1px solid #a1a0a1;
}

.content-box-contact {
  padding: 60px 0;
}

/* ================================================
|   |   |   |   Vertical Headings
================================================ */

.vertical-heading {
  margin-bottom: 20px;
}

.vertical-heading h5 {
  color: #cd0000;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  word-spacing: 10px;
  display: inline-block;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  top: 85px;
  left: -53px;
}

.vertical-heading h2 {
  margin: 0 0 0 35px;
  font-size: 42px;
  font-weight: 100;
  line-height: 45px;
}


/* ================================================
|   |   |   |   About 01
================================================ */

#about .vertical-heading h2 {
  color: #454545;
  margin-top: 90px;
}

#about .about-right {
  margin-top: 100px;
}

#about .about-left {

}

#about .vertical-heading h5 {
  color: #cd0000;
  top: 150px;
  word-spacing: 3px;
  margin-top: 35px;
}

.image-main {
  width: 234px;
  height: 234px;
}

.about-main:hover .about-overlay {
  opacity: 1;
}

.about-main {
  position: relative;
}

.image-main {
  border: 2px solid #454545;
  border-radius: 50%;
}



.about-overlay {
  background-color: rgba(69, 69, 69, .9);
  position: absolute;
  top: 0;
  left: 0;
  width: 234px;
  height: 234px;
  margin: 0 auto;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  border-radius: 50% !important;
}


.about-text {
  font-size: 17px;
  text-align: center;
}

.about-text-bold {
  /*color: #cd0000;*/
  /*font-weight: 700;*/
}

.about-right p:first-child {
  margin-bottom: 30px;
}

.about-bottom {
  margin-top: 5px;
}

.about-bottom img {
  margin: 0 auto;
}

/* ================================================
|   |   |   |   About 02
================================================ */

.about-02 {
  background-color: #a1a0a1;
}

.about-h {
  min-height: 331px !important;
}

.about-item {
  background-color: #dcdcdc;
  padding: 40px 30px;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.1);
  box-shadow: 0 0 10px rgba(0,0,0,.1);
}

.about-item .react-icon-about {
  font-size: 42px;
  margin: 0;
}


.about-item h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.about-item hr {
  width: 45px;
  height: 3px;
  background-color: #cd0000;
  margin: 0 auto;
  border: none;
}

.about-item p {
  margin-top: 20px;
}

/* Hover state */

.about-item:hover {
  background-color: #cd0000;
}
a
.about-item:hover i,
.about-item:hover .react-icon-about,
.about-item:hover h3,
.about-item:hover p {
  color: #dcdcdc;
}

.about-item:hover hr {
  background-color: #dcdcdc;
}

.about-item:hover i,
.about-item:hover .react-icon-about {
  transform: translateY(-5px);
}

/* Smooth transition */
.about-item,
.about-item i,
.about-item .react-icon-about,
.about-item hr {
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
}


/* ================================================
|   |   |   |   Team
================================================ */

.team-left .vertical-heading {
  min-height: 160px;
}

#team .vertical-heading h2 {
  color: #454545;
  margin-top: 25px;
}

#team .vertical-heading h5 {
  top: 88px;
}

.team-member {
  margin: 12px;
  position: relative;
  border: 1px solid #000000;
}




.team-member-overlay {
  /*background-color: rgba(69, 69, 69, .9);*/
  background-color: rgba(14, 14, 14, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  /*border: 1px solid #454545;*/
}

.team-member-overlay-2 {
  /*background-color: rgba(69, 69, 69, .9);*/
  background-color: rgba(14, 14, 14, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
  /*border: 1px solid #454545;*/
}

.team-member-info {
  width: 100%;
  height: 100%;
  padding: 80px 0;
}

.team-member-info-2 {
  width: 100%;
  height: 100%;
  padding: 80px 0;
  margin-top: -20px;
}

.team-member-info h6 {
  color: #dcdcdc;
  font-size: 16px;
  margin: 0;
}

.team-member-info-2 h6 {
  color: #dcdcdc;
  font-size: 16px;
  margin: 0;
}

.team-member-info p {
  color: #dcdcdc;
}
.team-member-info-2 p {
  color: #dcdcdc;
  margin: 0 !important;
}

.social-text-m {
  line-height: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
}


.team-member:hover .team-member-overlay {
  opacity: 1;
}


/* ================================================
|   |   |   |   Social Icons
================================================ */
ul.social-list {
  padding: 0;
  margin-top: 0;
  text-align: center;
  margin-bottom: 12px;
}

ul.social-list li {
  display: inline-block;
  padding: 5px;
}

ul.social-list li a {
  border: #dcdcdc;
  width: 35px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  color: #dcdcdc;
  border-radius: 50%;
  /*-webkit-transition: all 400ms linear;*/
  /*transition: all 400ms linear;*/
}

ul.social-list .instagram-bg-color a:hover {
  /*background: #f09433;*/
  /*background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);*/
  /*background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);*/
  /*background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);*/
  border-color: transparent;
  animation: GradientT 300ms ease-in;
  border: 2px solid #d02464;
  color: #d02464;
  background: #dcdcdc;
}

ul.social-list .instagram-bg-color a {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  /*border-color: transparent;*/
  animation: GradientT 300ms ease-in;
  color: #dcdcdc;
  border: 2px solid #d02464;
  /*border: 1px solid #dcdcdc;*/
}

ul.social-list .youtube-bg-color a:hover {
  background: #FF0000;
  border-color: transparent;
  animation: GradientT 300ms ease-in;
}

ul.social-list .facebook-bg-color a:hover {
  background: #dcdcdc;
  /*background: #a1aca1;*/
  border-color: transparent;
  animation: GradientT 300ms ease-in;
  border: 2px solid #3b5998;
  color: #3b5998;
}

.contact-icon-margin {
  margin-bottom: 5px;
  /*margin-right: 2px;*/
}

ul.social-list .facebook-bg-color a {
  background: #3b5998;
  /*border-color: transparent;*/
  animation: GradientT 300ms ease-in;
  color: #dcdcdc;
  border: 2px solid #3b5998;
}

ul.social-list .twitter-bg-color a:hover {
  background: #00aced;
  border-color: transparent;
  animation: GradientT 300ms ease-in;
}

/* Social List 2 */
/*ul.social-list {*/
/*    padding: 0;*/
/*    margin-top: 20px;*/
/*}*/

/*ul.social-list li {*/
/*    display: inline-block;*/
/*    padding: 0;*/
/*}*/

/*ul.social-list li a {*/
/*    border: #dcdcdc;*/
/*    width: 35px;*/
/*    height: 35px;*/
/*    display: inline-block;*/
/*    line-height: 35px;*/
/*    color: #dcdcdc;*/
/*    border-radius: 50%;*/
/*    !*-webkit-transition: all 400ms linear;*!*/
/*    !*transition: all 400ms linear;*!*/
/*}*/

/*ul.social-list li:nth-child(1) a:hover {*/
/*    background: #f09433;*/
/*    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);*/
/*    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);*/
/*    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);*/
/*    border-color: transparent;*/
/*    animation: GradientT 800ms ease-in;*/
/*}*/

/*ul.social-list li:nth-child(2) a:hover {*/
/*    background: #FF0000;*/
/*    border-color: transparent;*/
/*    animation: GradientT 800ms ease-in;*/
/*}*/

/*ul.social-list li:nth-child(3) a:hover {*/
/*    background: #3b5998;*/
/*    border-color: transparent;*/
/*    animation: GradientT 800ms ease-in;*/
/*}*/

/*ul.social-list li:nth-child(4) a:hover {*/
/*    background: #00aced;*/
/*    border-color: transparent;*/
/*    animation: GradientT 800ms ease-in;*/
/*}*/

@keyframes GradientT {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

/* ================================================
|   |   |   |   Slider Buttons
================================================ */

.owl-theme .owl-nav [class*=owl-] {
  color: #454545 !important;
  font-size: 40px !important;
  background: none !important;
  margin: 5px !important;
}

.owl-theme .owl-nav [class*=owl-]  {
  background: none !important;
  color: #454545 !important;
}

/* ================================================
|   |   |   |   Statement
================================================ */

#statement {
  background: url("./assets/Influence_is_power.png") no-repeat fixed center;
  background-size: cover;
}

#statement .content-box-lg {
  background: rgba(0,0,0,0.8);
}

.tech-statement h3 {
  color: #dcdcdc;
  font-size: 28px;
  line-height: 45px;
}

.tech-statement p {
  font-size: 14px;
  color: #dcdcdc;
  font-family: "Raleway", sans-serif;
  margin-top: 8px;
}

/* ================================================
|   |   |   |   Services 02 (Responsive Tabs)
================================================ */

#myDiv {
  margin: 0 auto;
  -webkit-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
  box-shadow:0 0 10px rgba(0, 0, 0, 0.5);
}


#portfolio-wrapper {
  background-color: #dcdcdc;
}

#portfolio-wrapper-3 {
  background-color: #dcdcdc;
}

#services .vertical-heading h2 {
  margin-bottom: 50px;
}

#services .vertical-heading h5 {
  top: 65px;
}

.contact-margin-t {
  margin-top: 40px;
}

.video-style {
  display: block;
  background-color: #454545;
}

.services-02 {
  background: white;
}

/*.tab-bg {*/
/*    margin-top: 80px;*/
/*    !*margin-left: -60px;*!*/
/*}*/

#services-tabs ul {
  margin-bottom: 10px;
}

#services-tabs ul li {
  padding: 5px 30px;
}

#services-tabs ul li a {
  font-family: 'Raleway', sans-serif;
  font-size: 13px;
  color: #454545;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0;
  text-decoration: none;
}

/* Tab active state */

#services-tabs ul li.r-tabs-state-active a {
  color: #cd0000;
  font-weight: 700;
  border-bottom: 3px solid #cd0000;
  padding-bottom: 5px;
  -webkit-transition: border-color 300ms linear;
  transition: border-color 300ms linear;
}

.service-tab .col-md-6 {
  padding: 0;
}

.service-tab video {
  width: 100%;
  margin: 0 auto;
}

.service-tab .tab-bg {
  background: #FFFFFF;
  padding: 20px 30px 39px;
  min-height: 300px;
  border: 1px solid #a1a0a1;
}

.service-tab h2 {
  font-size: 26px;
  color: #454545;
  margin-bottom: 20px;
  font-weight: 200;
}

.service-tab h3 {
  font-size: 24px;
  margin-bottom: 30px;
  color: #454545;
  font-weight: bold;
}

.service-tab p {
  line-height: 24px;
  margin-bottom: 30px;
  font-weight: 700;
  color: #454545;
}

.service-tab p a {
  color: #cd0000;
}

.service-tab p a:link {
  text-decoration: none;
}

.service-tab p a:hover {
  text-decoration: none;
  color: #500000;
}

.service-tab p a:visited {
  text-decoration: none;
}

.service-tab p a:active {
  text-decoration: none;
}

/* ================================================
|   |   |   |   Show Page
================================================ */

.show-button {
  text-align: center;
  /*margin-top: 15px;*/
  margin-top: 20px;
  margin-bottom: 10px;
}

.mfp-title {
  padding-right: 0;
}

.mfp-counter {
  display: none;
}

.client-show-instructions {
  text-align: center;
  color: #cd0000;
  font-weight: 600;
}

/* ================================================
|   |   |   |   Clients
================================================ */
#portfolio .vertical-heading {
  margin-top: 20px;
  /*margin-bottom: 30px;*/
  margin-bottom: 0;
}
#portfolio .vertical-heading h5 {
  top: 70px;
}

#portfolio .vertical-heading h2 {
  padding-bottom: 30px;
}

#portfolio-2 .vertical-heading {
  margin-top: 20px;
  margin-bottom: 30px;
}
#portfolio-2 .vertical-heading h5 {
  top: 70px;
}

#portfolio-2 .vertical-heading h2 {
  padding-bottom: 30px;
}
.row.no-gutters [class*=col-] {
  padding: 0;
}

.portfolio-item {
  /*overflow: hidden;*/
  position: relative;
}

#services .brand-thin {
  font-weight: 100;
  font-size: 35px !important;
  margin-bottom: 30px;
}

/* Filter CSS */
.client-border {
  border: 1px solid #a1a0a1;
}

.client-border-2 {
  border: 1px solid #a1a0a1;
  /*background-color: #dcdcdc;*/
}

#isotope-filters {
  /*margin-bottom: 5px;*/
  margin-top: -62px;
  padding: 20px 0 20px 0;
  text-align: center;
}

#isotope-filters button {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  /*background: transparent;*/
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  margin: 3px;
}

#isotope-filters button span {
  display: block;
  -webkit-transition: border-color .4s ease-in-out;
  transition: border-color .4s ease-in-out;
}

#isotope-filters button.active span {
  color: #cd0000;
  border-bottom: 3px solid #cd0000;
}


/* Portfolio filters */

#isotope-filters-2 {
  /*margin-bottom: 5px;*/
  margin-top: -62px;
  padding: 20px 0 20px 0;
  text-align: center;
}

#isotope-filters-2 button {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  /*background: transparent;*/
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

#isotope-filters-2 button span {
  display: block;
  -webkit-transition: border-color .4s ease-in-out;
  transition: border-color .4s ease-in-out;
}

#isotope-filters-2 button.active span {
  color: #cd0000;
  border-bottom: 3px solid #cd0000;
}



.client-custom {
  display: inline-block;
}



/* Zoom Effect */
.portfolio-item img {
  width: 100%;
  height: auto;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  /*transition: transform .5s ease, -webkit-transform .5s ease ;*/
}

/*.portfolio-item:hover img {*/
/*  -webkit-transform: scale(1.1);*/
/*  transform: scale(1.1);*/
/*}*/

.portfolio-item:hover img {
  /*-webkit-transform: scale(1.1);*/
  /*transform: scale(1.1);*/
}

/* Overlay Effect */
.portfolio-item-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*background: rgba(14, 14, 14, 0.9);*/
  background: #500000cc;
  cursor: zoom-in;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.portfolio-item-overlay-service {
  position: absolute;
  /*top: 85%;*/
  bottom: 0;
  left: 0;
  right: 0;
  /*background: rgba(14, 14, 14, 0.9);*/
  background:   #500000cc;
  cursor: zoom-in;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  overflow: hidden;
}

.portfolio-item-overlay-service h3 {
  /*color: #e60000;*/
  color: #fff;
  text-transform: uppercase;
  /* font-size: 30px; */
  font-size: 13px;
  margin: 12px;
  /* padding: 0; */
  /* transform: translateY(-20%); */
  transition: all 0.5s;
  text-align: center;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  position: relative;
}

@media(max-width: 767px) {
  .portfolio-item-overlay-service h3 {
    margin: 20px;
  }
}

/* Item Details Effect*/
.portfolio-item-details {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0 15px 0 15px;
}

.portfolio-item-details h3 {
  /*color: #e60000;*/
  color: #fff;
  text-transform: uppercase;
  /*font-size: 30px;*/
  /*font-size: 23px;*/
  font-size: 18px;
  margin: 0;
  padding: 0;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 700;
}

.portfolio-item-details span {
  display: inline-block;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  height: 6px;
  width: 30px;
  margin: 11px auto 5px auto;
}

.portfolio-item-details p {
  /*font-size: 12px;*/
  font-size: 10px;
  color: #dcdcdc;
  text-transform: uppercase;
  margin: 0 auto;
  padding: 0;
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  line-height: 22px;
  width: 85%;

  /*limit text*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

}

/* Show Overlay on mouse over */
.portfolio-item:hover .portfolio-item-details h3 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.portfolio-item:hover .portfolio-item-details p {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-item:hover .portfolio-item-overlay {
  opacity: 1;
}

/* ================================================
|   |   |   |   Stats
================================================ */
#stats {
  background: url('assets/InstagramLikes1.jpg') no-repeat fixed center;
  background-size: cover;
}

#stats .content-box-md-stats {
  background: rgba(0,0,0, 0.9);
}

#stats .vertical-heading {
  margin-bottom: 50px;
}

#stats .vertical-heading h2 {
  color: #dcdcdc;
}

#stats .vertical-heading h5 {
  color: #dcdcdc;
  top: 62px;
  font-weight: 300;
  word-spacing: 3px;
}

.stats-item {
  background: rgba(61, 68, 68, 0.6);
  border-radius: 4px;
  min-height: 170px;
  padding: 25px 50px;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  margin-top: 25px;
}

/*.stats-item i {*/
/*  font-size: 42px;*/
/*  margin: 0 0 6px 0;*/
/*  -webkit-transition: all 400ms linear;*/
/*  transition: all 400ms linear;*/
/*}*/

.stats-item div {
  /*font-size: 42px;*/
  font-size: 50px;
  margin: 0 0 6px 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.stats-item .stats-item-icon {
  /*font-size: 42px;*/
  font-size: 50px;
  margin: 0 0 6px 0;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
}

.stats-item-icon {
  margin-bottom: 10px;
}

.stats-item-t div {
  color: #38A1F3;
  background: #dcdcdc;
  padding: 30px;
  /*padding: 50px;*/
  border-radius: 50%;
  display: inline-block;
  /*margin-bottom: 20px;*/
  /*margin-top: 20px;*/
}




.stats-item-y div {
  color: #cd0000;
  background: #dcdcdc;
  padding: 30px;
  /*padding: 50px;*/
  border-radius: 50%;
  display: inline-block;
}

.stats-item-tik div {
  color: #4cb5ad;
  background: #dcdcdc;
  padding: 30px;
  /*padding: 50px;*/
  border-radius: 50%;
  display: inline-block;
}


/*.stats-item-f i {*/
/*  color: #4267b2;*/
/*  background: #dcdcdc;*/
/*  padding: 30px;*/
/*  border-radius: 50%;*/
/*}*/

.stats-item-f div {
  color: #4267b2;
  background: #dcdcdc;
  padding: 30px;
  /*padding: 50px;*/
  border-radius: 50%;
  display: inline-block;
}

/*.stats-item-i i {*/
/*  color: #F56040;*/
/*  background: #dcdcdc;*/
/*  padding: 30px;*/
/*  border-radius: 50%;*/
/*}*/

.stats-item-i div {
  color: #F56040;
  background: #dcdcdc;
  padding: 30px;
  /*padding: 50px;*/
  border-radius: 50%;
  display: inline-block;
}


/*.stats-item:hover i {*/
/*  font-size: 42px;*/
/*  !*color: #dcdcdc;*!*/
/*  margin: 0 0 6px 0;*/
/*}*/

.stats-item:hover div {
  /*font-size: 42px;*/
  font-size: 50px;
  /*color: #dcdcdc;*/
  margin: 0 0 6px 0;
}

.stats-item h3 {
  font-size: 32px;
  color: #dcdcdc;
  margin: 5px 0 5px 0;
}

.stats-item .counter {
  font-size: 32px;
  color: #dcdcdc;
  margin: 5px 0 5px 0;
}

.stats-item p {
  color: #dcdcdc;
  margin: 0;
}

.stats-item-t:hover {
  background: #38A1F3;
}

.stats-item-y:hover {
  background: #cd0000;
}

.stats-item-f:hover {
  background: #4267b2;
}

.stats-item-tik:hover {
  background: #4cb5ad;
}

.stats-item-i:hover {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
}

/*.stats-item:hover i {*/
/*  -webkit-transform: translateY(-5px);*/
/*  transform: translateY(-5px);*/
/*}*/

.stats-item:hover div {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.social-links-margin {
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.arrow-white {
  color: #dcdcdc;
}

.arrow-dark {
  color: #454545;
}

/* ================================================
|   |   |   |   Brands
================================================ */
#brands .horizontal-heading {
  /*margin-bottom: 30px;*/
}

#clients-list {
  padding: 0 0 0 0;
}

.client {
  padding: 0 10px;
  // max-width: 150px;
  max-width: 400px;
  margin: 0 auto;
  line-height: 100px;
}

.client img {
  display: inline-block !important;
}

.brand-bold {
  color: #cd0000;
  font-weight: 700 !important;

}

.brand-thin {
  font-weight: 100;
  font-size: 35px !important;
}

/* ================================================
|   |   |   |   Contact
================================================ */
/* Contact Left */
.form-styles-c {
  background-color: #a1a0a1;
}

.line-br-color {
  border: 1px solid #454545;
}

#contact-left .vertical-heading h5 {
  top: 70px;
  color: #dcdcdc;
}

#contact-left .vertical-heading h2 {
  padding-top: -40px;
  color: #dcdcdc;
}

#contact .office li i {
  text-align: center;
  margin-left: -3px;
  margin-right: 10px;
}

.office {
  margin-top: 230px;
  margin-bottom: 10px;
}

.contact-left-m {
  margin-left: -8px;
}


#contact {
  /*background-color: #454545;*/
  background-color: #500000cc;
}

#contact-left p {
  margin: 25px 0 20px 0;
}

ul.office-details {
  list-style: none;
  padding: 0;
  text-align: center;
  margin-bottom: 0;
}

ul.office-details li {
  font-family: "Open Sans", sans-serif;
  margin-top: 8px;
  margin-bottom: 12px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: #dcdcdc;
  font-weight: 600;
}

ul.office-details li a {
  color: #500000;
  margin-top: 3px;
  text-decoration: none;
}

ul.office-details li:hover {
  color: #dcdcdc;
  text-decoration: none;
}

ul.office-details li:hover a {
  color: #dcdcdc;
  text-decoration: none;
}

ul.office-details li:link a {
  text-decoration: none;
}

ul.office-details li:visited a {
  text-decoration: none;
}

ul.office-details li i {
  font-size: 25px;
  width: 45px;
}


#contact-left ul.social-list li a {
  color: #dcdcdc;
  border: 2px solid #dcdcdc;
  text-align: center;
}

#contact-left ul.social-list {
  /*text-align: center;*/
  /*vertical-align: center;*/
  margin-bottom: 30px;
}

#contact-left ul.social-list li a:hover {
  color: #fff;
  border-color: transparent;
}

/* Contact Right */
.form-t-c {
  color: #454545;
  font-weight: bold;
}

.form-p-c {
  color: #454545;
  font-weight: bold;
  font-size: 16px;
}

#contact-right {
  padding: 25px 30px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  border-radius: 4px;
}

#contact-right h4 {
  font-size: 24px;
  text-align: center;
}

#contact-right p {
  /*margin-bottom: 25px;*/
  text-align: center;
}

#contact-right form .form-control {
  font-family: "Open Sans", sans-serif;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 24px;
  margin: 6px;
}

#contact-right form textarea.form-control {
  min-height: 164px;
}



.contact-main-form-s {
  text-align: center;
  margin: 0 auto;
}

.contact-main-form-h {
  text-align: center;
  margin-bottom: 60px;
}

.contact-main-form-h h5 {
  color: #fff !important;
}

.contact-main-form-h h2 {
  color: #fff;
}



/* ================================================
|   |   |   |   Google Map
================================================ */
#map {
  height: 600px;
}

#google-map .row {
  display: block !important;
}

/* ================================================
|   |   |   |   Footer
================================================ */
footer {
  background: #dcdcdc;
  padding: 20px;
}

footer p {
  color: #454545;
  line-height: 20px;
}

footer p span {
  font-weight: bold;
  color: #454545;
}

.marg-top {
  margin-top: 10px;
}


/* ================================================
|   |   |   |   Navigation
================================================ */
.navbar {
  padding: 10px 0 20px 0;
  -webkit-transition: all 100ms linear;
  transition: all 100ms linear;
}

.site-nav-wrapper {
  padding: 0 85px;
}

.navbar-brand {
  padding: 13px 15px;
}

ul.navbar-nav > li > a {
  font-family: "Raleway", sans-serif;
  color: #dcdcdc;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

ul.navbar-nav > li > a:hover,
ul.navbar-nav > li > a:focus {
  background: none;
  color: #cd0000;
}

/* White Navigation */

.white-nav-top {
  background: #dcdcdc;
  padding: 10px 0;
  /*-webkit-box-shadow: 0 8px 6px -9px #999;*/
  /*box-shadow: 0 8px 6px -9px #999;*/
  z-index: 2;
  border: 1px solid #a1a0a1;
}

.white-nav-top ul.navbar-nav > li > a {
  color: #454545;
}

.white-nav-top ul.navbar-nav > li > a:hover,
.white-nav-top ul.navbar-nav > li > a:focus {
  color: #cd0000;
}

/* Scroll spy active state */

.white-nav-top ul.navbar-nav > li.active > a {
  color: #cd0000;
  font-weight: 500;
}





.offer-header {
  font-size: 25px !important;
  font-weight: 700;
}

.offer-text {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 30px;
}

.offer-break {
  border: 1px solid #dcdcdc;
}

#home-4 {
  height: 72px !important;
}

.template-p-detail {
  //text-indent: 20px;
  margin-top: 30px !important;
  margin-bottom: 40px !important;

  @media(max-width: 1399px) {
    width: 72% !important;
  }

  @media(max-width: 1199px) {
    width: 80% !important;
  }

  @media(max-width: 991px) {
    width: 100% !important;
  }

  @media(max-width: 450px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.template-p-detail-2 p {
  font-size: 16px;
  text-align: center;
  text-indent: 20px;
  margin-bottom: 20px;
}

.template-p-detail-2 h1, h2, h3, h4, h5, h6 {
  padding-top: 10px;
  font-weight: bold;
  color: #500000;
}


.owl-carousel .owl-stage { display: flex; align-items: center; }