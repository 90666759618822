.offer-p-detail {
  text-align: center;
  line-height: 1.5em;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 20px;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.offer-button-page {
  text-align: center;
}

.offering-info {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  margin-top: 30px;

  @media(max-width: 767px) {
    width: 100%;
  }
}

.map-color-8 {
    background-color: #F4F4F4;
    border: 1px solid #46494C;
    border-radius: 5px;
}

.offer-subheading {
  margin-top: 20px;
}

.paypal-button-styles {
  max-width: 400px;
  margin: 60px auto;
}