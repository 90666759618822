#home-4 {
  //background: none;
  /* height: 69px; */
  background-color: #500000cc;
  height: 80px;
}

#home-bg-video-2 {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  //background: url(../video/websitewarehouse6.jpg) no-repeat;
  background-size: cover;
}

#home-overlay-2 {
  background-color: rgba(0,0,0,0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.email__design-s {
  color: #500000;
  text-decoration: none;
  font-weight: 800;
}

.influencer-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}



.meet-n-great__style {
  color: #fff;
  margin-top: 1.5rem;
  font-size: 1.3rem;
  text-shadow: 1px 1px 2px black, 0 0 25px red, 0 0 5px darkred;
}


.meet-n-great__style:hover {
  color: #db8a8a;
}

.meet-n-great__style-i {
  //color: #fff;
  color: #500000;
  margin-top: 1.5rem;
  //font-size: 1.3rem;
  text-shadow: 1px 1px 2px white, 0 0 10px orange, 0 0 2px yellow;
  display: inline-block;
  margin-bottom: 0;
  margin-right: .8rem;
  font-size: 1.6rem;
  font-weight: 700;


  @media(max-width: 767px) {
    font-size: 1.4rem;
  }
}


.meet-n-great__style-i__heading {
  color: #500000;
  margin-top: 1.5rem;
  //font-size: 1.3rem;
  text-shadow: 1px 1px 2px white, 0 0 10px orange, 0 0 2px yellow;
  display: inline-block;
  margin-bottom: 0;
  margin-right: .8rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.meet-n-great__style-i__heading:hover {
  //color: #db8a8a;
  color: #F4F4F4;
  text-shadow: 1px 1px 2px black, 0 0 10px red, 0 0 2px darkred;

}



.meet-n-great__style-em {
  margin-right: 0.5rem;
  font-size: 1.3rem;

  @media(max-width: 767px) {
    font-size: 1.4rem;
  }
}


.meet-n-great__style-i:hover {
  //color: #db8a8a;
  color: #F4F4F4;
  text-shadow: 1px 1px 2px black, 0 0 10px red, 0 0 2px darkred;

}